<template>
    <div class="action-noti">
        <div class="title" v-html="msg" />
        <div class="body" v-if="body">
            <div v-html="body" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'ActionNoti',
    props: ['message'],
    computed: {
        content() {
            return this.$mustParse(this.message.content) || {}
        },
        mType() {
            return this.message.mtype
        },
        body() {
            if (this.mType === 'schedule-finish-noti') {
                return `${this.content.name} / ${this.$options.filters.asAge(this.content.birthday)} / ${
                    this.content.job
                } 님이 일정 선택을 완료했어요`
            }
            return this.content.body
        },
        msg() {
            if (this.mType === 'schedule-finish-noti') {
                return '남성분과 약속을 진행해주세요'
            }
            return this.content.msg
        },
    },
}
</script>
